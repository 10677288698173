@import "color.scss";

.about-page {

  .main {
    padding: 0;
  }
  .main__content {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.78) 76.50%);
  }

  .subtitle {
    margin-bottom: 55px;
  }
}

.news {
  padding-top: 80px;
  @media screen and (max-width: 1199px) {
    padding-top: 60px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 15px;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 310px 310px;
    gap: 20px 20px;
    grid-auto-flow: row;
    grid-template-areas:
    "item1 item2 item2"
    "item1 item3 item3";
    @media screen and (max-width: 1199px) {
      grid-template-rows: 225px 225px;
      gap: 16px 16px;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-template-rows: 175px 310px 175px;
      grid-template-areas:
      "item2"
      "item1"
      "item3";
    }
  }

  .item1 {
    grid-area: item1;
    @media screen and (max-width: 767px) {

    }
  }

  .item2 {
    grid-area: item2;
    @media screen and (max-width: 767px) {

    }
  }

  .item3 {
    grid-area: item3;
    @media screen and (max-width: 767px) {

    }
  }

  .list-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 32px;
    @media screen and (max-width: 1199px) {
      padding: 24px;
    }
    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 176px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 21.16%, rgba(0, 0, 0, 0.87) 86.77%);
      z-index: -3;
    }
  }

  .item-title {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 3.2rem;
    color: $background;
    @media screen and (max-width: 1199px) {
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
    @media screen and (max-width: 1199px) {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }

  .item-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -5;
  }
}

.table {
  padding: 40px 0;
  @media screen and (max-width: 1199px) {
    padding: 30px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 20px 0;
  }

  &__block {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-top: 32px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      width: 100%;
      margin: 20px auto 8px;
    }
  }

  .table__desc {
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 8px;
    }
    @media screen and (max-width: 425px) {
      justify-content: space-between;
    }
  }

  .desc-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 373px;
    height: 222px;
    margin-bottom: 20px;
    background: linear-gradient(90deg, #EBEDF1 50.21%, rgba(251, 251, 251, 0.00) 100%);
    &:last-child {
      margin-bottom: 0;
    }
    @media screen and (max-width: 1199px) {
      width: 277px;
      height: 164px;
    }
    @media screen and (max-width: 767px) {
      width: 161px;
      height: 95px;
      margin-bottom: 0;
    }
  }

  .item-wrap {
    display: flex;
    flex-direction: column;
    width: fit-content;
  }

  .small-text {
    width: fit-content;
    font-size: 2.4rem;
    line-height: 3.2rem;
    color: $grey;
    &:last-child {
      margin-left: auto;
    }
    @media screen and (max-width: 1199px) {
      font-size: 1.8rem;
      line-height: 2.3rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 1rem;
      line-height: 150%;
    }
  }

  .large-text {
    width: fit-content;
    font-size: 9rem;
    font-weight: 700;
    line-height: 75%;
    color: $grey;
    @media screen and (max-width: 1199px) {
      font-size: 6.7rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 3.8rem;
    }
  }

  &__wrapper {
    width: 70%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__body {
    position: relative;
    width: 100%;
    height: 465px;
    padding: 20px 20px 0;
    background: $item-color;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 140%;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 70px;
      border-radius: 5px;
      background: linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
      @media screen and (max-width: 767px) {
        height: 30px;
      }
    }
    @media screen and (max-width: 1199px) {
      height: 345px;
    }
    @media screen and (max-width: 767px) {
      height: 210px;
      padding: 5px 10px 0;
      margin: 0 auto;
    }
  }

  .headline {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
    @media screen and (max-width: 767px) {
      margin-bottom: 5px;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: -11px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $dark;
      opacity: .1;
      @media screen and (max-width: 767px) {
        bottom: 5px;
      }
    }
  }

  .headline-title {
    color: $dark;
    font-weight: 700;
    font-size: 1.3rem;
    @media screen and (max-width: 767px) {
      font-size: 0.6rem;
    }
    &:first-child {
      flex-basis: 15%;
    }

    &:nth-child(2) {
      flex-basis: 30%;
    }

    &:nth-child(3) {
      flex-basis: 30%;
    }

    &:last-child {
      flex-basis: 17%;
    }
  }

  &__scroll {
    height: 380px;
    padding-bottom: 20px;
    overflow-x:auto;
    @media screen and (max-width: 1199px) {
      height: 275px;
    }
    @media screen and (max-width: 767px) {
      height: 170px;
      padding-bottom: 10px;
    }
  }

  &__scroll-wrapper {
    width: 100%;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 140%;
    @media screen and (max-width: 767px) {
      font-size: 0.6rem;
    }
  }

  .city {
    flex-basis: 15%;
  }

  .company {
    flex-basis: 30%;
  }

  .object {
    flex-basis: 30%;
  }

  .sum {
    flex-basis: 17%;
  }

  &__subtitle {
    margin-top: 20px;
    color: $dark;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 140%;
    @media screen and (max-width: 767px) {
      margin-top: 10px;
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 155%;
    }
  }
}

.certificate {
  padding: 40px 0;
  @media screen and (max-width: 1199px) {
    padding: 30px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 20px 0;
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__text {
    width: 32%;
    @media screen and (max-width: 1199px) {
      width: 40%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 16px;
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 4rem;
    @media screen and (max-width: 767px) {
      margin-bottom: 8px;
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  &__desc {
    width: 350px;
    font-size: 1.8rem;
    line-height: 150%;
    @media screen and (max-width: 1199px) {
      width: 278px;
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  &__background {
    position: relative;
    width: 66%;
    height: 423px;
    @media screen and (max-width: 1199px) {
      width: 52.5%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 270px;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__btn {
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 260px;
    @media screen and (max-width: 767px) {
      position: static;
      max-width: 330px;
      margin-top: 32px;
    }
  }

  .btn-desc {
    margin-bottom: 27px;
    text-align: end;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 3.2rem;
    @media screen and (max-width: 767px) {
      width: 175px;
      margin-bottom: 25px;
      font-size: 1.6rem;
      line-height: 2rem;
    }
    @media screen and (max-width: 359px) {
      width: 150px;
      font-size: 1.4rem;
    }
  }

  .action {
    width: 100%;
    height: 56px;
    font-weight: 600;
    line-height: 140%;
  }

  &__slider-wrapper {
    position: absolute;
    bottom: -10px;
    left: -60px;
    width: 180px;
    height: 245px;
    @media screen and (max-width: 767px) {
      left: auto;
      right: -5px;
      bottom: -85px;
      width: 124px;
      height: 173px;
    }
  }

  &__slider {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .slider-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 15px;
    left: 0;
    z-index: 5;
  }

  &__button-prev,
  &__button-next {
    position: absolute;
    top: 100px;
    width: 42px;
    height: 42px;
    z-index: 5;
  }

  &__button-prev {
    right: -20px;
    &:before {
      right: 15px;
    }
  }

  &__button-next {
    left: -20px;
    &:before {
      left: 15px;
    }
  }
}

.about-page {

  .slider {
    padding-top: 40px;
    @media screen and (max-width: 1199px) {
      padding-top: 30px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }

  .map {
    .subtitle {
      margin-left: 0;
      margin-bottom: 0;
      @media screen and (max-width: 425px) {
        width: 100%;
      }
    }

    .img-wrapper {
      margin-top: -135px;
      @media screen and (max-width: 767px) {
        margin-top: 0;
      }
    }
  }
  .contacts {
    padding-top: 80px;
    @media screen and (max-width: 1199px) {
      padding-top: 60px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 40px;
    }
  }
}

