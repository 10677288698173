@import "color.scss";

h1.title {
  font-size: 7.2rem;
  font-weight: 700;
  line-height: 7.2rem;
  @media screen and (max-width: 1199px) {
    font-size: 5.8rem;
    line-height: 6.6rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 4.4rem;
    line-height: 4.8rem;
  }
}

h2.title {
  font-size: 5.8rem;
  font-weight: 700;
  line-height: 6.6rem;
  @media screen and (max-width: 1199px) {
    font-size: 4.4rem;
    line-height: 4.8rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
}

h3.title {
  font-size: 4.4rem;
  font-weight: 700;
  line-height: 4.8rem;
  @media screen and (max-width: 1199px) {
    font-size: 3.2rem;
    line-height: 4rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 2.4rem;
    line-height: 3.2rem
  }
}