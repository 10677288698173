@import "color.scss";

.main {
  padding: 50px 0 25px;
  @media screen and (max-width: 1199px) {
    padding: 40px 0 20px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 0;
  }

  .container {
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    .sidebar {
      margin-right: 20px;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 50px;
    @media screen and (max-width: 1199px) {
      padding-bottom: 40px;
    }
    @media screen and (max-width: 920px) {
      height: 450px;
      padding: 72px 15px 47px;
    }

    .title {
      margin-bottom: 24px;
      font-size: 7.2rem;
      line-height: 7.2rem;
      color: $white-color;
      @media screen and (max-width: 1199px) {
        font-size: 5.8rem;
        line-height: 6.6rem;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
        font-size: 4.4rem;
        line-height: 4.8rem;
      }
    }
  }

  .subtitle {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 3.2rem;
    color: $white-color;
    @media screen and (max-width: 1199px) {
      font-size: 2rem;
    }
  }

  .primary {
    max-width: 190px;
    padding: 12px 20px;
    margin-top: auto;
    &:hover {
      background-color: $background;
    }
    @media screen and (max-width: 767px) {
      max-width: 330px;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -5;
  }
}

.advantage {
  padding: 25px 0;
  @media screen and (max-width: 1199px) {
    padding: 20px 0;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  .list-item {
    @media screen and (max-width: 767px) {
      flex-basis: 48%;
      margin-bottom: 30px;
      &:nth-child(3) {
        margin-bottom: 0;
      }
      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }

  .item-img {
    width: 90px;
    height: 90px;
    object-fit: contain;
    @media screen and (max-width: 1199px) {
      width: 70px;
      height: 70px;
    }
    @media screen and (max-width: 767px) {
      width: 50px;
      height: 50px;
    }
  }

  .item-text {
    margin-top: 14px;
    font-size: 2.4rem;
    line-height: 3.2rem;
    @media screen and (max-width: 1199px) {
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
    @media screen and (max-width: 920px) {
      font-size: 1.6rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
      line-height: 2rem;
    }

    .strong {
      display: block;
    }
  }
}

.navigate {
  padding-top: 25px;
  padding-bottom: 40px;
  @media screen and (max-width: 1199px) {
    padding-top: 20px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 20px;
  }

  &__list {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 32.5% 32.5% 32.5%;
    grid-template-rows: 314px 314px;
    gap: 15px 15px;
    grid-template-areas:
    "item1 item2 item3"
    "item1 item4 item5";
    @media screen and (max-width: 1199px) {
      grid-template-rows: 230px 230px;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 49% 49%;
      grid-template-rows: 170px 170px 170px;
      gap: 8px 8px;
      grid-template-areas:
      "item2 item3"
      "item1 item1 "
      "item4 item5";
    }
  }

  .list-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 32px;
    background: linear-gradient(360deg, #050505 0%, rgba(0, 0, 0, 0.00) 82.49%);
    transition: .3s;
    overflow: hidden;

    &:hover {

      .item-img {
        transform: translate(-50%, -50%) scale(1.2);
      }
      .item-title {
        color: $block-color;
      }
    }
    @media screen and (max-width: 1199px) {
      padding: 24px;
    }
    @media screen and (max-width: 767px) {
      padding: 16px;
    }
    @media screen and (max-width: 425px) {
      padding: 10px;
    }
  }

  .item1 {
    grid-area: item1;
    @media screen and (max-width: 767px) {
      order: 3;
    }
  }
  .item2 {
    grid-area: item2;
    @media screen and (max-width: 767px) {
      order: 1;
    }
  }
  .item3 {
    grid-area: item3;
    @media screen and (max-width: 767px) {
      order: 2;
    }
  }
  .item4 {
    grid-area: item4;
    @media screen and (max-width: 767px) {
      order: 4;
    }
  }
  .item5 {
    grid-area: item5;
    @media screen and (max-width: 767px) {
      order: 5;
    }
  }

  .item-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -5;
    transition: .3s;
  }

  .item-cert {
    width: 210px;
    height: 150px;
    margin-bottom: 20px;
    object-fit: contain;
    @media screen and (max-width: 1199px) {
      width: 170px;
      height: 120px;
      margin-bottom: 8px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .item-title {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 4rem;
    color: $white-color;
    transition: .3s;
    @media screen and (max-width: 1199px) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
}

.form {
  padding-top: 40px;
  padding-bottom: 60px;
  @media screen and (max-width: 1199px) {
    padding-top: 30px;
    padding-bottom: 55px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .subtitle {
    margin-top: 7px;
    margin-bottom: 24px;
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 3.2rem;
    color: $grey;
    @media screen and (max-width: 1199px) {
      font-size: 1.8rem;
      margin-bottom: 16px;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.6rem;
      margin-bottom: 24px;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
    }

    .form-body {
      @media screen and (max-width: 1199px) {
        margin-bottom: 16px;
      }
      @media screen and (max-width: 920px) {
        margin-bottom: 24px;
      }
    }
  }
}

.delivery {
  padding-top: 40px;
  padding-bottom: 80px;
  @media screen and (max-width: 1199px) {
    padding-bottom: 60px;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 40px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__text {
    width: 47%;
    @media screen and (max-width: 1199px) {
      width: 40%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .title {
    margin-bottom: 50px;
    @media screen and (max-width: 1199px) {
      margin-bottom: 40px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 32px;
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  .subtitle {
    margin-bottom: 32px;
    font-size: 3.2rem;
    line-height: 4rem;
    &:last-child {
      margin-bottom: 0;
    }
    @media screen and (max-width: 1199px) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 24px;
      font-size: 1.8rem;
      line-height: 150%;
    }
  }

  &__img-wrapper {
    position: relative;
    width: 50%;
    height: 633px;
    @media screen and (max-width: 1199px) {
      width: 60%;
      height: 462px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 271px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -60px;
      transform: translate(0, -50%);
      background-image: url("../img/backgrounf-decor.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 1106px;
      height: 1106px;
      z-index: -5;
      @media screen and (max-width: 1199px) {
        width: 772px;
        height: 772px;
      }
      @media screen and (max-width: 767px) {
        left: 50%;
        transform: translate(-50%, -50%);
        width: 453px;
        height: 453px;
      }
    }
  }

  &__img {
    width: 829px;
    height: 100%;
    object-fit: contain;
    @media screen and (max-width: 1199px) {
      width: 605px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 271px;
      margin: 0 auto;
    }
  }

}

.map {
  //height: 895px;
  //padding-top: 40px;
  //padding-bottom: 75px;
  @media screen and (max-width: 1199px) {
    //height: 693px;
    //padding-top: 30px;
    //padding-bottom: 30px;
  }
  @media screen and (max-width: 767px) {
    //height: 401px;
    //padding-top: 20px;
    //padding-bottom: 20px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .title {
    margin-bottom: 22px;
    @media screen and (max-width: 1199px) {

    }
    @media screen and (max-width: 767px) {
      max-width: fit-content;
      order: 1;
      br {
        display: none;
      }
    }
    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }

  .img-wrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    @media screen and (max-width: 1199px) {
      //width: 860px;
      //height: 477px;
    }
    @media screen and (max-width: 920px) {
      //width: 720px;
      //height: 400px;
    }
    @media screen and (max-width: 767px) {
      //width: 315px;
      //height: 175px;
      order: 3;
    }

    @media screen and (max-width: 767px) {
      //width: 280px;
      //height: 155px;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .subtitle {
    max-width: 410px;
    margin-left: auto;
    font-size: 2.4rem;
    line-height: 3.2rem;
    //z-index: 10;
    @media screen and (max-width: 1199px) {
      max-width: 395px;
    }
    @media screen and (max-width: 767px) {
      max-width: 310px;
      margin-top: 16px;
      margin-bottom: 26px;
      margin-left: 0;
      font-size: 1.8rem;
      line-height: 150%;
      order: 2;
    }
    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
}

.slider {
  padding-top: 150px;
  padding-bottom: 75px;
  @media screen and (max-width: 1199px) {
    padding-top: 60px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  &__list {
    position: relative;
    max-width: 1180px;
    width: 100%;
    padding: 0 15px;
    margin: 40px auto 0;
    overflow: hidden;
    @media screen and (max-width: 1199px) {
      margin-top: 35px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 24px;
    }
    .slider-shadow {
      position: absolute;
      top: 0;
      right: -5px;
      width: 180px;
      height: 100%;
      background: linear-gradient(270deg, #FBFBFB 5%, rgba(251, 251, 251, 0.00) 100%);
      z-index: 5;
      @media screen and (max-width: 767px) {
        right: -80px;
      }
    }

    .swiper-slide {
      max-width: 520px;
      cursor: pointer;
      @media screen and (max-width: 767px) {
        max-width: 280px;
      }
    }
  }

  &__img-wrapper {
    position: relative;
    height: 380px;
    @media screen and (max-width: 767px) {
      height: 205px;
    }
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__date {
    position: absolute;
    bottom: 0;
    font-size: 11.7rem;
    font-weight: 700;
    line-height: 75%;
    letter-spacing: -4.709px;
    color: $grey;
    mix-blend-mode: color-burn;
    @media screen and (max-width: 767px) {
      font-size: 6.3rem;
    }

  }

  &__title {
    margin-top: 31px;
    margin-bottom: 16px;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 3.2rem;
    @media screen and (max-width: 767px) {
      margin-top: 15px;
      margin-bottom: 8px;
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }

  &__subtitle {
    font-size: 1.8rem;
    line-height: 150%;
    color: $grey;
    .accent {
      color: $dark;
    }
    @media screen and (max-width: 767px) {
      font-size: 1rem;
    }
  }

  &__button-prev {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
    z-index: 10;
  }
}

.links {
  padding-top: 65px;
  padding-bottom: 75px;
  @media screen and (max-width: 1199px) {
    padding-top: 55px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .title {
    margin-bottom: 48px;
    font-size: 5.8rem;
    font-weight: 700;
    line-height: 6.6rem;
    @media screen and (max-width: 1199px) {
      margin-bottom: 33px;
      font-size: 4.4rem;
      line-height: 4.8rem;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 24px;
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }
}

.contacts {
  padding-bottom: 80px;
  @media screen and (max-width: 1199px) {
    padding-bottom: 60px;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 10px;
  }

  .title {
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-top: 20px;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 27.5%;
    @media screen and (max-width: 1199px) {
      width: 31.5%;
    }
    @media screen and (max-width: 959px) {
      width: 40.5%;
    }
    @media screen and (max-width: 767px) {
      align-items: center;
      width: 100%;
    }
  }

  &__address {
    font-size: 2.4rem;
    line-height: 3.2rem;
    @media screen and (max-width: 767px) {
      text-align: center;
      font-size: 1.8rem;
      line-height: 150%;
    }
  }

  &__phone {
    display: block;
    margin-top: 36px;
    margin-bottom: 8px;
    font-size: 2.4rem;
    line-height: 4rem;
    font-weight: 500;
    color: $dark;
    transition: .3s;
    &:hover {
      color: $logo-text;
    }
    @media screen and (max-width: 1199px) {
      font-size: 3.2rem;
    }
    @media screen and (max-width: 767px) {
      margin-top: 24px;
      margin-bottom: 4px;
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  &__time {
    font-size: 1.8rem;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }

  &__email {
    display: block;
    margin-top: 36px;
    margin-bottom: 38px;
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 700;
    color: $dark;
    transition: .3s;
    &:hover {
      color: $logo-text;
    }
    @media screen and (max-width: 767px) {
      margin-top: 24px;
      margin-bottom: 20px;
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
  }

  .action {
    padding: 12px 20px;
    max-width: 177px;
    @media screen and (max-width: 767px) {
      max-width: 320px;
      width: 100%;
      font-size: 1.6rem;
    }
  }

  &__map {
    width: 66%;
    height: 332px;
    object-fit: cover;
    @media screen and (max-width: 1199px) {
      width: 62%;
    }
    @media screen and (max-width: 959px) {
      width: 55%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 24px;
    }
  }
}