@import "color.scss";

.header {
  padding: 20px 0;
  @media screen and (max-width: 767px) {
    padding: 10px 0;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__menu {
    max-width: 310px;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
      position: absolute;
      width: calc(100% + 40px);
      max-width: none;
      max-height: 0;
      top: 45px;
      left: -20px;
      background: $background;
      overflow: hidden;
      transition: .3s;
      z-index: 15;
      &.active {
        max-height: 1000px;
        padding: 20px 0;
      }
    }
  }

  .menu {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
    }
    &:first-child {
      margin-bottom: 8px;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }

  .menu-item {
    margin-right: 12px;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    @media screen and (max-width: 767px) {
      margin: 10px 0;
    }
  }

  .menu-link {
    color: $dark;
    transition: .3s;
    &:hover {
      color: $logo-text;
    }
  }

  .header__btn-menu {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
      margin: 80px auto 50px;
    }
  }

  .menu-btn {
    position: relative;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.6rem;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: -15px;
      transform: translate(0, -50%);
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: $yellow;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-right: 20px;
    @media screen and (max-width: 920px) {
      display: none;
    }
  }

  .contact-link {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.4rem;
    color: $dark;
    transition: .3s;
    &:hover {
      color: $logo-text;
    }
  }

  .header__btn {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .header__btn-catalog {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
      margin-left: auto;
      z-index: 5;
    }
  }

  &__menu-btn {
    position: relative;
    display: none;
    width: 24px;
    height: 24px;
    margin-left: 24px;
    overflow: hidden;
    z-index: 5;
    @media screen and (max-width: 767px) {
      display: block;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      right: 2px;
      width: 10px;
      border-bottom: 2px solid $dark;
      border-radius: 2px;
      transition: .3s;
    }
    &:before {
      top: 8.7px;
    }
    &:after {
      bottom: 3.7px;
    }

    &.active {

      &:before,
      &:after {
        right: -100%;
      }

      .line {
        &:first-child {
          top: 11.5px;
          transform: translate(-50%, 0) rotate(45deg);
        }

        &:last-child {
          bottom: 11.5px;
          transform: translate(-50%, 0) rotate(-45deg);
        }
      }
    }
  }

  .line {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    display: block;
    width: 20px;
    height: 1.5px;
    border-radius: 2px;
    background-color: $dark;
    transition: .3s;
    &:first-child {
      top: 3.7px;
    }
    &:last-child {
      bottom: 8.7px;
    }
  }

  .mobile-contact {
    display: none;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    @media screen and (max-width: 767px) {
      display: flex;
    }
    @media screen and (max-width: 425px) {
      justify-content: space-between;
    }
    .contact-link {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      @media screen and (max-width: 350px) {
        font-size: 1.1rem;
      }
    }

    .contact-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      object-fit: contain;
      @media screen and (max-width: 350px) {
        width: 18px;
        height: 18px;
      }
    }
  }
}