@import "color.scss";

button.primary {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  background-color: $yellow;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.7rem;
  color: $dark;
  &:hover {
    background-color: transparent;
    box-shadow: 0 0 0 2px $yellow inset;
  }
  @media screen and (max-width: 1199px) {
    padding: 12px 20px;
  }
}

a.primary {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  background-color: $yellow;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.7rem;
  color: $dark;
  &:hover {
    background-color: transparent;
    box-shadow: 0 0 0 2px $yellow inset;
  }
  @media screen and (max-width: 1199px) {
    padding: 12px 20px;
  }
}

button.secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10.5px 15px;
  background-color: $yellow;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 2.4rem;
  color: $dark;
  &:hover {
    background-color: transparent;
    box-shadow: 0 0 0 2px $yellow inset;
  }
  @media screen and (max-width: 1199px) {
    padding: 6px 14px;
  }
}

button.action {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding: 10px 24px;
  background-color: $dark;
  font-size: 1.6rem;
  font-weight: 700;
  color: $background;
  &:hover {
    background-color: transparent;
    box-shadow: 0 0 0 2px $dark inset;
    color: $dark;
  }
  @media screen and (max-width: 1199px) {
    padding: 12px 20px;
  }
  @media screen and (max-width: 767px) {
    height: auto;
    font-size: 1.2rem;
  }
}

a.action {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding: 10px 24px;
  background-color: $dark;
  font-size: 1.6rem;
  font-weight: 700;
  color: $background;
  &:hover {
    background-color: transparent;
    box-shadow: 0 0 0 2px $dark inset;
    color: $dark;
  }
  @media screen and (max-width: 1199px) {
    padding: 12px 20px;
  }
  @media screen and (max-width: 767px) {
    height: auto;
    font-size: 1.2rem;
  }
}


.slider-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $gray-lighten;
  box-shadow: 0 0 0 2px $grey-light inset;
  opacity: .8;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.btn-next,
.btn-prev {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    background-repeat: no-repeat;
    background-size: contain;
    width: 10px;
    height: 15px;
  }
}

.btn-next {
  &:before {
    left: 24px;
    background-image: url("../img/arrow.svg");
    transform: translate(0, -50%) rotate(180deg);
  }
}

.btn-prev {
  &:before {
    right: 24px;
    background-image: url("../img/arrow.svg");
  }
}

.certificate__pagination {

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border: 1px solid $grey-light;
    background: transparent;
    opacity: 1;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: $grey-light;
    opacity: 1;
  }
}

.close-btn {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  @media screen and (max-width: 1199px) {
    top: 40px;
    right: 40px;
  }
  @media screen and (max-width: 767px) {
    top: 24px;
    right: 24px;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 2px;
    border-radius: 4px;
    background-color: $dark;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
