$yellow: #FEC50C;
$dark: #050606;
$grey: #525252;
$grey-light: #919FB6;
$gray-lighten: #DDE4EE;
$callback-color: #D7DCE6;

$background: #FBFBFB;
$section-color: #EAECF1;
$block-color: #C5CAD4;
$item-color: #EFF1F4;
$white-color: #FFF;

$green: #1FC056;
$logo-text: #018DD3;
$border-decor: #D9D9D9;