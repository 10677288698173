@import "color.scss";

.footer {
  position: relative;
  @media screen and (max-width: 767px) {
    padding-bottom: 70px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    background-image: url("../img/backgrounf-decor.png");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    width: 1400px;
    height: 200px;
    z-index: -5;
    @media screen and (max-width: 1199px) {
      width: 880px;
      height: 140px;
    }
    @media screen and (max-width: 767px) {
      width: 900px;
      height: 160px;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 100px;
    padding-bottom: 70px;
    @media screen and (max-width: 1199px) {
      padding-top: 70px;
      padding-bottom: 50px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      opacity: 0.2;
      background: $grey;
    }
  }

  &__contact {
    color: $grey;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 140%;
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, .2);
    @media screen and (max-width: 767px) {
      margin-bottom: -70px;
      font-size: 1.1rem;
    }
  }

  .diple {
    color: $grey;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 140%;
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, .2);
    @media screen and (max-width: 767px) {
      margin-bottom: -70px;
      font-size: 1.1rem;
    }
  }

  .top-scroll {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: fixed;
    bottom: 30px;
    right: -100%;
    z-index: 50;
    border-radius: 3px;
    background-color: $dark;
    border: 2px solid $gray-lighten;
    transition: .3s;
    &:after {
      content: '';
      position: absolute;
      background-image: url("../img/arrow-top.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 22px;
      height: 22px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &.active {
      right: 20px;
    }
    @media screen and (max-width: 767px) {
      width: 48px;
      height: 48px;
      bottom: 75px;
    }
  }
}

.modal-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(60px);
  -webkit-backdrop-filter: blur(60px);

  display: none;
  z-index: 100;
  &.active {
    display: block;
  }
}

.modal {

  &__callback {
    max-width: 940px;
    padding: 50px 90px 50px 335px;
    @media screen and (max-width: 1199px) {
      max-width: 750px;
      padding: 55px 40px 30px 320px;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 25px;
      background-image: url("../img/modal-img-1.png");
      background-size: cover;
      background-repeat: no-repeat;
      width: 327px;
      height: 373px;
      @media screen and (max-width: 1199px) {
        width: 283px;
        height: 330px;
      }
      @media screen and (max-width: 767px) {
        top: 135px;
        left: 50%;
        bottom: auto;
        transform: translate(-50%, 0);
        background-image: url("../img/modal-img-2.png");
        width: 131px;
        height: 186px;
      }
      @media screen and (max-width: 339px) {
        top: 185px;
      }
    }

    .modal-title {
      margin-bottom: 32px;
      font-size: 3.2rem;
      font-weight: 700;
      line-height: 4rem;
      @media screen and (max-width: 1199px) {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }

    .modal-body {
      @media screen and (max-width: 767px) {
        margin-top: 210px;
      }
      @media screen and (max-width: 340px) {
        margin-top: 230px;
      }
    }

    .form-input {
      @media screen and (max-width: 1199px) {
        max-width: 255px;
      }
      @media screen and (max-width: 767px) {
        max-width: 100%;
      }
    }

    .modal-submit {
      max-width: 185px;
      margin-left: auto;
      margin-right: 145px;
      @media screen and (max-width: 1199px) {
        margin-right: 70px;
      }
    }
  }

  &__order {
    max-width: 615px;
    padding: 50px 70px;
    @media screen and (max-width: 1199px) {
      max-width: 550px;
      padding: 40px;
    }

    .modal-title {
      font-size: 3.2rem;
      font-weight: 700;
      line-height: 4rem;
      @media screen and (max-width: 1199px) {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }

    .modal-subtitle {
      margin-top: 8px;
      margin-bottom: 32px;
      @media screen and (max-width: 767px) {
        margin-bottom: 24px;
      }
    }

    .form-input {
      max-width: 355px;
      @media screen and (max-width: 767px) {
        max-width: 100%;
      }
    }

    .modal-submit {
      max-width: 185px;
      margin-left: auto;
      margin-right: 170px;
    }
  }

  &__question {
    max-width: 745px;
    padding: 50px 40px;
    @media screen and (max-width: 1199px) {
      max-width: 750px;
      padding: 40px;
    }

    .modal-title {
      margin-bottom: 32px;
      font-size: 3.2rem;
      font-weight: 700;
      line-height: 4rem;
      @media screen and (max-width: 1199px) {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }


    .modal-body {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1199px) {
        flex-direction: row;
        justify-content: space-between;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    .input-wrap {
      display: flex;
      flex-direction: column;
    }

    .comment-wrap {
      width: 100%;
      margin-bottom: 16px;
      @media screen and (max-width: 1199px) {
        width: 320px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 330px;
      }
    }

    .input-comment {
      height: 120px;
      @media screen and (max-width: 1199px) {
        height: 250px;
      }
      @media screen and (max-width: 767px) {
        height: 100px;
      }
    }

    .form-wrapper {
      @media screen and (max-width: 1199px) {
        display: flex;
        flex-direction: column;
        max-width: 335px;
        width: 100%;
      }
      @media screen and (max-width: 959px) {
        max-width: 310px;
      }
    }

    .input-wrap {
      width: 100%;
      max-width: 315px;
      margin-bottom: 0;
      @media screen and (max-width: 1199px) {
        max-width: 100%;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .check-block {
      width: 100%;
      max-width: 315px;
      margin-left: auto;
      margin-top: 24px;
      margin-bottom: 24px;
      @media screen and (max-width: 1199px) {
        margin-left: 0;
        margin-top: 26px;
        margin-bottom: 32px;
      }
    }

    .check-wrap {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      padding-left: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .check-label {
      max-width: fit-content;
      margin-bottom: 0;
      font-size: 1.6rem;
      line-height: 2.2rem;
      font-weight: 400;
      color: $dark;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $background;
        -webkit-box-shadow: 0 0 0 2px rgba(221, 228, 238, 1) inset;
        -moz-box-shadow: 0 0 0 2px rgba(221, 228, 238, 1) inset;
        box-shadow: 0 0 0 2px rgba(221, 228, 238, 1) inset;
      }
    }

    .check-input[type=radio] {
      display: none;
    }

    .check-input[type=radio]:checked + .check-label:before {
      -webkit-box-shadow: 0 0 0 6px rgba(254, 197, 12, 1) inset;
      -moz-box-shadow: 0 0 0 6px rgba(254, 197, 12, 1) inset;
      box-shadow: 0 0 0 6px rgba(254, 197, 12, 1) inset;
    }

    .modal-submit {
      max-width: 315px;
      margin-left: auto;
      @media screen and (max-width: 1199px) {
        margin-left: 0;
      }
    }

    .modal-desc {
      position: absolute;
      left: 50px;
      bottom: 50px;
      display: flex;
      padding-left: 28px;
      @media screen and (max-width: 1199px) {
        left: 40px;
        bottom: 40px;
      }
      @media screen and (max-width: 767px) {
        position: relative;
        left: auto;
        bottom: auto;
        margin-top: 24px;
      }
    }

    .dot {
      position: absolute;
      left: 0;
      top: 3px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 8px;
      background-color: $green;
      -webkit-box-shadow: 0 0 0 2px rgba(217, 217, 217, 1) inset;
      -moz-box-shadow: 0 0 0 2px rgba(217, 217, 217, 1) inset;
      box-shadow: 0 0 0 2px rgba(217, 217, 217, 1) inset;
    }

    .desc-text {
      width: 195px;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 150%;
      @media screen and (max-width: 1199px) {
        width: 289px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        font-size: 1.6rem;
      }
    }
  }

  &__tube,
  &__price,
  &__calc {
    display: flex;
    justify-content: space-between;
    max-width: 940px;
    padding: 50px 75px 100px 50px;
    @media screen and (max-width: 1199px) {
      max-width: 750px;
      padding: 40px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .modal-title {
      max-width: 375px;
      margin-bottom: 32px;
      font-size: 3.2rem;
      font-weight: 700;
      line-height: 4rem;
      @media screen and (max-width: 1199px) {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
        font-size: 2rem;
      }
    }

    .modal-body {
      max-width: 410px;
      width: 100%;
    }

    .input-comment {
      height: 90px;
      @media screen and (max-width: 767px) {
        height: 77px;
      }
    }

    .input-wrap {
      flex-direction: column;
      max-width: 195px;
      margin-bottom: 0;
      @media screen and (max-width: 1199px) {
        max-width: 100%;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }

    .form-input {
      @media screen and (max-width: 1199px) {
        max-width: 100%;
      }
      @media screen and (max-width: 767px) {

      }
    }

    .modal-upload {
      display: flex;
      align-items: center;
      margin-top: 24px;
      margin-bottom: 24px;
      @media screen and (max-width: 767px) {
        margin-top: 8px;
      }
    }

    .modal-submit {
      width: 100%;
      max-width: 195px;
      padding: 15px 12px;
      margin-top: 24px;
      @media screen and (max-width: 767px) {
        max-width: 100%;
      }
    }

    .modal-desc {
      position: absolute;
      bottom: 45px;
      left: 110px;
      display: flex;
      align-items: end;
      z-index: -5;
      @media screen and (max-width: 1199px) {
        flex-direction: column;
        align-items: center;
        bottom: 40px;
        left: 57px;
      }
      @media screen and (max-width: 767px) {
        position: static;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 16px;
      }
    }

    .modal-img {
      margin-right: 54px;
      width: 220px;
      height: 280px;
      object-fit: contain;
      @media screen and (max-width: 1199px) {
        width: 180px;
        height: 231px;
        margin-right: 0;
        margin-bottom: 45px;
      }
      @media screen and (max-width: 767px) {
        width: 50px;
        height: 58px;
        margin-bottom: 0;
      }
    }

    .desc-subtitle {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -28px;
        transform: translate(0, -50%);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $green;
        -webkit-box-shadow: 0 0 0 3px rgba(217, 217, 217, 1) inset;
        -moz-box-shadow: 0 0 0 3px rgba(217, 217, 217, 1) inset;
        box-shadow: 0 0 0 3px rgba(217, 217, 217, 1) inset;
      }
      @media screen and (max-width: 1199px) {
        max-width: 240px;
        &:before {
          display: none;
        }
      }
      @media screen and (max-width: 767px) {
        max-width: 215px;
      }
    }
  }

  &__price {
    padding: 50px 75px 130px 50px;
    @media screen and (max-width: 1199px) {
      max-width: 750px;
      padding: 40px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    .modal-headline {
      display: flex;
      flex-direction: column;
    }

    .modal-title {
      margin-bottom: 8px;
      @media screen and (max-width: 1199px) {
        max-width: 245px;
      }
      @media screen and (max-width: 425px) {
        max-width: 215px;
      }
    }

    .subtitle {
      max-width: 358px;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 150%;
      @media screen and (max-width: 1199px) {
        max-width: 265px;
        font-size: 1.6rem;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 24px;
        font-size: 1.4rem;
      }
    }

    .modal-body {
      @media screen and (max-width: 1199px) {
        max-width: 365px;
      }
    }

    .input-comment {
      @media screen and (max-width: 767px) {
        height: 97px;
      }
    }

    .modal-upload {
      @media screen and (max-width: 767px) {
        margin: 16px 0;
      }
    }

    .modal-desc {
      bottom: 0;
      left: 58px;
      @media screen and (max-width: 1199px) {
        flex-direction: column;
        align-items: center;
        bottom: 40px;
        left: 57px;
      }
      @media screen and (max-width: 767px) {
        position: static;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 16px;
      }
    }
    .modal-img {
      width: 293px;
      height: 301px;
      margin-right: 125px;
      @media screen and (max-width: 1199px) {
        width: 167px;
        height: 215px;
        margin-right: 0;
        margin-bottom: 5px;
      }
      @media screen and (max-width: 767px) {
        width: 50px;
        height: 58px;
        margin-bottom: 0;
      }
    }

    .desktop {
      @media screen and (max-width: 1199px) {
        display: none;
      }
    }

    .mobile {
      display: none;
      @media screen and (max-width: 1199px) {
        display: block;
      }
    }

    .desc-subtitle {
      max-width: 380px;
      margin-bottom: 50px;
      @media screen and (max-width: 1199px) {
        max-width: 240px;
        margin-bottom: 0;
        &:before {
          display: none;
        }
      }
      @media screen and (max-width: 767px) {
        max-width: 215px;
      }
      @media screen and (max-width: 425px) {
        max-width: 185px;
      }
    }
  }

  &__calc {
    display: flex;
    flex-direction: column;
    max-width: 940px;
    padding: 50px;
    @media screen and (max-width: 1199px) {
      max-width: 750px;
      padding: 40px;
    }
    @media screen and (max-width: 767px) {

    }
    .modal-title {
      max-width: fit-content;
      margin-bottom: 5px;
      @media screen and (max-width: 425px) {
        max-width: 215px;
      }
    }

    .subtitle {
      max-width: 540px;
      margin-bottom: 32px;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 150%;
      @media screen and (max-width: 1199px) {
        font-size: 1.6rem;
      }
      @media screen and (max-width: 767px) {
        font-size: 1.4rem;
      }
    }

    .modal-body {
      max-width: 435px;
      @media screen and (max-width: 1199px) {
        max-width: 365px;
      }
      @media screen and (max-width: 767px) {

      }
    }

    .input-wrap {
      flex-direction: column;
      max-width: 210px;
      margin-bottom: 0;
      @media screen and (max-width: 1199px) {
        max-width: 100%;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }

    .modal-upload {
      margin-top: 18px;
      margin-bottom: 23px;
    }

    .modal-submit {
      width: 100%;
      max-width: 220px;
      padding: 15px 12px;
      margin-top: 24px;
      @media screen and (max-width: 1199px) {
        max-width: 100%;
      }

      @media screen and (max-width: 767px) {
      }
    }

    .modal-desc {
      left: auto;
      right: 22px;
      bottom: 0;
      @media screen and (max-width: 1199px) {
        display: flex;
        flex-direction: column;
        right: 40px;
        bottom: 50px;
      }
      @media screen and (max-width: 767px) {
        position: static;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 16px;
      }
    }

    .modal-img {
      width: 398px;
      height: 464px;
      margin-right: 0;
      @media screen and (max-width: 1199px) {
        order: 1;
        width: 274px;
        height: 392px;
        margin-bottom: 22px;
      }
      @media screen and (max-width: 767px) {
        width: 50px;
        height: 58px;
        margin-bottom: 0;
      }
    }

    .desktop {
      @media screen and (max-width: 1199px) {
        display: none;
      }
    }

    .mobile {
      display: none;
      @media screen and (max-width: 1199px) {
        display: block;
      }
    }

    .desc-subtitle {
      max-width: 240px;
      margin-bottom: 50px;
      &:before {
        display: none;
      }
      @media screen and (max-width: 1199px) {
        order: 2;
        margin-bottom: 0;
      }
      @media screen and (max-width: 767px) {
        max-width: 215px;
      }
      @media screen and (max-width: 425px) {
        max-width: 185px;
      }
    }
  }
}

.toast {
  position: fixed;
  right: -100%;
  top: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 210px;
  height: 50px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  background-color: $logo-text;
  transition: .3s;
  &__text {
    font-size: 1.5rem;
    font-weight: 600;
    color: $dark;
  }
  &.active {
    right: 0;
  }
}

.quick-menu {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 15px 0;
  background: rgba(48, 49, 56, 0.50);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  z-index: 50;
  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__link {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    @media screen and (max-width: 425px) {
      width: 30px;
      height: 30px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      @media screen and (max-width: 425px) {
        width: 80%;
        height: 80%;
      }
    }
  }

  .phone {
    &:before {
      background-image: url("../img/phone-white.svg");
    }
  }

  .whats {
    &:before {
      background-image: url("../img/whatsapp-white.svg");
    }
  }

  .email {
    &:before {
      background-image: url("../img/email-white.svg");
    }
  }

  .telega {
    &:before {
      background-image: url("../img/telegram-white.svg");
    }
  }
}

