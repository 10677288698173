@import "color.scss";

.breadcrumbs {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 8px auto 20px;
  padding: 0 20px;
  @media screen and (max-width: 1199px) {
    margin: 4px auto 16px;
  }

  &__item {

  }

  &__item-link {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: $grey;
    @media screen and (max-width: 1199px) {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }

  &__arrow {
    //display: block;
    margin: 0 5px;
  }

  .breadcrumb_last {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: $dark;
    @media screen and (max-width: 1199px) {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
}