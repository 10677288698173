@import "color.scss";
@import "title.scss";
@import "button.scss";
@import "breadcrumbs.scss";
@import "container.scss";
@import "sidebar.scss";
@import "form.scss";
@import "header.scss";
@import "main.scss";
@import "footer.scss";
@import "catalog.scss";
@import "about.scss";
@import "product.scss";

* {
  outline: none;
  box-sizing: border-box;

  &:after,
  &:before {
    transition: inherit;
  }
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}


body {
  font-family: 'Rubik', sans-serif;
  overflow-x: hidden;
  background-color: $background;
}

:root {
  font-size: 10px;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: $dark;
  transition: .3s;
}

input,
button,
textarea {
  outline: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: .3s;
  outline: 0;
}

.logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
  width: 100%;
  @media screen and (max-width: 1199px) {
    max-width: 235px;
  }
  @media screen and (max-width: 767px) {
    max-width: fit-content;
    z-index: 5;
  }
}

.logo-img {
  width: 165px;
  height: 45px;
  object-fit: contain;
  @media screen and (max-width: 1199px) {
    width: 130px;
    height: 35px;
  }
  @media screen and (max-width: 767px) {
    margin-right: 4px;
  }
}

.logo-line {
  height: 45px;
  width: 1px;
  background-color: $dark;
  @media screen and (max-width: 1199px) {
    height: 35px;
  }
}

.logo-text {
  max-width: 115px;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.7rem;
  color: $dark;
  @media screen and (max-width: 1199px) {
    max-width: 90px;
    font-size: 1rem;
    line-height: 1.3rem;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}


.strong {
  font-weight: 700;
}

.links-list {
  display: flex;
  flex-wrap: wrap;

  .list-item {
    padding: 24px 37px;
    margin: 12px;
    border-radius: 34px;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    background-color: $section-color;
    border: 1px solid $gray-lighten;
    color: $dark;
    &:hover {
      background-color: transparent;
    }
    @media screen and (max-width: 767px) {
      padding: 8px 16px;
      margin: 8px;
    }
  }
}


.modal {
  width: calc(100% - 40px);
  max-height: 95vh;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  background-color: $item-color;
  transition: .3s;
  z-index: 110;
  &.active {
    transform: translate(-50%, -50%) scale(1);
  }
  @media screen and (max-width: 767px) {
    max-width: 330px;
    padding: 24px;
  }

  .close-btn {
    top: 40px;
    right: 40px;
    @media screen and (max-width: 1199px) {
      top: 20px;
      right: 20px;
    }
  }

  .modal-subtitle {
    font-size: 1.5rem;
    line-height: 2.2rem;
    font-weight: 400;
  }

  .desc-subtitle {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      font-size: 1.6rem;
    }
    @media screen and (max-width: 425px) {
      font-size: 1.4rem;
    }
  }

  .modal-body {
    width: 100%;
  }

  .input-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
    }
  }

  .comment-wrap {
    display: flex;
    flex-direction: column;
  }

  .input-comment {
    padding: 16px;
    border: 1px solid $gray-lighten;
    background: $background;
    resize: none;
    font-size: 1.6rem;
  }

  .input-wrap {
    display: flex;
    justify-content: space-between;
    padding: 0;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .input-label {
    position: static;
    max-width: fit-content;
    margin-bottom: 8px;
  }

  .modal-submit {
    width: 100%;
    @media screen and (max-width: 767px) {
      max-width: 100%;
      margin: 0;
    }
  }
}