@import "color.scss";

.container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}



//Точки для адаптива
@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1400px) {

}
@media screen and (max-width: 1199px) {

}
@media screen and (max-width: 767px) {

}
@media screen and (max-width: 425px) {

}