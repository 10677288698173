@import "color.scss";

.catalog {
  padding-bottom: 40px;
  @media screen and (max-width: 1199px) {
    padding-bottom: 30px;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 20px;
  }

  &__headline {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 55px 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.71) 12.85%, rgba(0, 0, 0, 0.80) 52.57%, rgba(217, 217, 217, 0.00) 100%);
    @media screen and (max-width: 1199px) {
      padding: 87px 0;
    }
    @media screen and (max-width: 767px) {
      padding: 60px 0;
    }
  }

  .title {
    max-width: 860px;
    text-align: center;
    color: $background;
    @media screen and (max-width: 1199px) {
      max-width: 475px;
    }
    @media screen and (max-width: 767px) {
      max-width: 290px;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -5;
  }

  &__filter {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 56px 0;
    @media screen and (max-width: 1199px) {
      padding: 48px 0;
    }
    @media screen and (max-width: 920px) {
      justify-content: normal;
      flex-wrap: wrap;
      padding: 24px 0;
      margin: 0 -8px;
    }
  }

  .filter-item {
    padding: 24px 40px;
    border-radius: 57px;
    background-color: $section-color;
    border: 1px solid $gray-lighten;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    cursor: pointer;
    transition: .3s;
    &:hover {
      background-color: transparent;
    }
    &.active {
      background-color: transparent;
    }
    @media screen and (max-width: 1199px) {
      padding: 16px 24px;
      font-size: 1.2rem;
      line-height: 2.7rem;
    }
    @media screen and (max-width: 920px) {
      padding: 8px 16px;
      margin: 8px;
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }

  .list-item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 280px;
    padding: 40px 55px 40px 395px;
    margin-bottom: 24px;
    border: 1px solid $border-decor;
    transition: .3s;
    &.hide {
      display: none;
    }
    @media screen and (max-width: 1199px) {
      height: 235px;
      padding: 32px 8px 32px 290px;
    }
    @media screen and (max-width: 920px) {
      padding-left: 190px;
    }
    @media screen and (max-width: 767px) {
      height: auto;
      padding: 16px;
    }
  }

  .item-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 279px;
    height: 100%;
    object-fit: cover;
    object-position: right;
    @media screen and (max-width: 1199px) {
      width: 200px;
    }
    @media screen and (max-width: 920px) {
      width: 170px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 600px;
    width: 100%;
    height: 100%;
    margin-left: auto;
    transition: .3s;
    @media screen and (max-width: 1199px) {
      max-width: 535px;
    }
    @media screen and (max-width: 920px) {

    }
    @media screen and (max-width: 767px) {
      max-width: none;
      max-height: 210px;
      margin-left: 0;
      &.active {
        max-height: 500px;
      }
    }
  }

  .item-title {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 4rem;
    @media screen and (max-width: 1199px) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 16px;
    }
  }

  .item-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 145px;
    height: 100%;
    overflow: hidden;
    @media screen and (max-width: 1199px) {
      max-height: 115px;
    }
    @media screen and (max-width: 920px) {

    }
    @media screen and (max-width: 767px) {
      flex-wrap: nowrap;
      max-height: none;
    }
    &.active {
      max-height: fit-content;
    }
  }

  .item-link {
    margin-top: 16px;
    margin-right: 20px;
    font-size: 1.8rem;
    line-height: 2rem;
    text-decoration-line: underline;
    transition: .3s;
    &:hover {
      color: $logo-text;
    }
    @media screen and (max-width: 1199px) {
      font-size: 1.6rem;
      margin-top: 8px;
    }
    @media screen and (max-width: 920px) {

    }
    @media screen and (max-width: 767px) {
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  .item-more {
    display: none;
    width: fit-content;
    padding: 12px 20px;
    margin-left: auto;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.4rem;
    cursor: pointer;
    transition: .3s;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}

.catalog-form {
  padding-top: 40px;
  padding-bottom: 40px;
  @media screen and (max-width: 1199px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__wrapper {
    position: relative;
    padding: 50px;
    background-color: $item-color;
    overflow: hidden;
    @media screen and (max-width: 1199px) {
      padding: 30px;
      &:after {
        content: '';
        position: absolute;
        top: 20px;
        right: -322px;
        background-image: url("../img/backgrounf-decor.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 644px;
        height: 644px;
        z-index: 1;
        @media screen and (max-width: 767px) {
          width: 511px;
          height: 511px;
          top: -400px;
          right: -316px;
        }
      }
    }
    @media screen and (max-width: 920px) {
      padding: 16px;
    }
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 14px 32px;
    }
  }

  .title {
    max-width: 830px;
    @media screen and (max-width: 1199px) {
      max-width: 600px;
    }
    @media screen and (max-width: 767px) {
      max-width: 500px;
      text-align: center;
    }
  }

  .subtitle {
    margin-top: 14px;
    margin-bottom: 50px;
    font-size: 2.4rem;
    line-height: 3.2rem;
    @media screen and (max-width: 1199px) {
      margin-top: 12px;
      margin-bottom: 42px;
      font-size: 1.8rem;
      line-height: 150%;
    }
    @media screen and (max-width: 767px) {
      text-align: center;
      margin-top: 8px;
      margin-bottom: 24px;
    }
  }

  &__body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 840px;
    width: 100%;
    z-index: 5;
    @media screen and (max-width: 920px) {
      max-width: 690px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .input-wrap {
      width: 330px;
      margin-bottom: 0;
      @media screen and (max-width: 920px) {
        width: 250px;
      }
      @media screen and (max-width: 767px) {
        max-width: 330px;
        width: 100%;
        margin-bottom: 16px;
        padding: 0;
      }
    }
    .primary {
      padding: 14px 30px;
      @media screen and (max-width: 920px) {
        padding: 14px 37px;
      }
      @media screen and (max-width: 767px) {
        max-width: 330px;
        width: 100%;
      }
    }
  }
}

.description {
  padding-top: 40px;
  padding-bottom: 100px;
  @media screen and (max-width: 1199px) {
    padding-top: 30px;
    padding-bottom: 60px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .title {
    margin-bottom: 5px;
    font-size: 5.8rem;
    font-weight: 700;
    line-height: 6.6rem;
    @media screen and (max-width: 1199px) {
      margin-bottom: 16px;
      font-size: 4.4rem;
      line-height: 4.8rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__text {
    width: 49%;
    font-size: 1.8rem;
    line-height: 150%;
    @media screen and (max-width: 1199px) {
      width: 68%;
      font-size: 1.6rem;
      line-height: 137.5%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      order: 2;
    }
  }

  &__img {
    width: 49%;
    height: 340px;
    object-fit: cover;
    @media screen and (max-width: 1199px) {
      width: 30%;
      height: 165px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 240px;
      margin-bottom: 24px;
      order: 1;
    }
    @media screen and (max-width: 425px) {
      height: 165px;
    }
  }
}

