@import "color.scss";

.sidebar {
  display: flex;
  flex-direction: column;
  max-width: 373px;
  width: 100%;
  @media screen and (max-width: 1199px) {
    max-width: 272px;
  }
  @media screen and (max-width: 920px) {
    display: none;
  }

  &__title {
    margin-bottom: 24px;
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 4rem;
    @media screen and (max-width: 1199px) {
      margin-bottom: 18px;
      font-size: 2.4rem;
      line-height: 3.9rem;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0 24px;
    margin-bottom: 4px;
    background-color: $item-color;
    font-size: 1.8rem;
    font-weight: 400;
    color: $grey;
    cursor: pointer;
    transition: .3s;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background-color: $block-color;
      color: $logo-text;
    }
    @media screen and (max-width: 1199px) {
      font-size: 1.6rem;
    }
  }
}
