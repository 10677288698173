@import "color.scss";

.form-body {
  position: relative;
  width: 66%;
  padding: 32px 32px 32px 187px;
  background-color: $item-color;
  @media screen and (max-width: 1199px) {
    width: 100%;
    max-width: 860px;
    margin: 0 auto;
  }

  @media screen and (max-width: 767px) {
    padding: 16px 16px 32px 108px;
  }

  @media screen and (max-width: 425px) {
    padding: 16px 16px 32px 16px;
  }
}


.input-wrap {
  display: flex;
  margin-bottom: 16px;
  @media screen and (max-width: 425px) {
    padding-left: 92px;
  }
}

.input-label {
  position: absolute;
  left: 32px;
  max-width: 140px;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #0E1932;
  @media screen and (max-width: 767px) {
    position: absolute;
    left: 16px;
    max-width: 77px;
    font-size: 1.4rem;
    word-wrap: break-word;
  }
  @media screen and (max-width: 425px) {
    position: absolute;
    left: 16px;
    max-width: 77px;
  }
}

.form-comment {
  max-width: 548px;
  width: 100%;
  height: 128px;
  padding: 16px;
  resize: none;
  border: 1px solid $gray-lighten;
  background-color: $background;
  font-size: 1.6rem;
  @media screen and (max-width: 1199px) {
    max-width: 641px;
  }
  @media screen and (max-width: 425px) {
    max-width: 100%;
  }
}

.form-input {
  position: relative;
  max-width: 330px;
  width: 100%;
  height: 56px;
  padding: 16px 16px 16px 48px;
  border: 1px solid $gray-lighten;
  background-color: $background;
  background-repeat: no-repeat;
  background-position: 15px 50%;
  font-size: 1.6rem;
  transition: .3s;
  @media screen and (max-width: 425px) {
    max-width: 100%;
  }
}

.input-text {

}

.input-name {
  background-image: url("../img/name.svg");
}

.input-phone {
  background-image: url("../img/phone.svg");
}

.input-email {
  background-image: url("../img/email.svg");
}

.form-upload {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  cursor: pointer;
  @media screen and (max-width: 425px) {
    margin-left: 92px;
  }
}

.upload-icon {
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-image: url("../img/upload-icon.svg");
}

.upload-name {
  position: relative;
  font-size: 1.6rem;
  line-height: 2.2rem;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px dashed rgba(14, 25, 50, .4);
  }
  @media screen and (max-width: 767px) {
    font-size: 1.4rem;
  }
}

.form-submit {
  @media screen and (max-width: 425px) {
    width: 100%;
  }
}

.form-email {
  position: relative;
  display: block;
  padding-left: 32px;
  margin-top: 25px;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.6rem;
  color: $dark;
  span {
    color: $grey;
    text-decoration: underline;
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    background-image: url("../img/email.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
  }
  @media screen and (max-width: 767px) {
    font-size: 1.4rem;
  }
  @media screen and (max-width: 425px) {
    font-size: 1.3rem;
  }
}

.callback-block {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 32.2%;
  padding: 32px 32px 53px 32px;
  background-color: $callback-color;
  @media screen and (max-width: 1199px) {
    flex-direction: row;
    align-items: center;
    max-width: 860px;
    width: 100%;
    padding-bottom: 37px;
    margin: 0 auto;
  }
  @media screen and (max-width: 920px) {
    flex-direction: column;
    max-width: 330px;
    padding: 24px 16px 32px 16px;
  }

  .img-wrapper {
    position: relative;
    width: 96px;
    height: 96px;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      background-color: $green;
      -webkit-box-shadow: 0 0 0 3px rgba(217, 217, 217, 1) inset;
      -moz-box-shadow: 0 0 0 3px rgba(217, 217, 217, 1) inset;
      box-shadow: 0 0 0 3px rgba(217, 217, 217, 1) inset;
    }
    @media screen and (max-width: 1199px) {
      margin-right: 24px;
    }
    @media screen and (max-width: 920px) {
      margin-right: auto;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    background-color: $grey-light;

  }

  .text-wrapper {
    @media screen and (max-width: 1199px) {
      max-width: 388px;
      width: 100%;
    }
    @media screen and (max-width: 920px) {
      max-width: 290px;
    }
  }

  &__title {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 4rem;
    @media screen and (max-width: 1199px) {
      margin-top: 0;
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
    @media screen and (max-width: 920px) {
      position: absolute;
      top: 40px;
      right: 16px;;
      max-width: 190px;
    }
    @media screen and (max-width: 355px) {
      top: 25px;
      max-width: 140px;
    }
  }

  .subtitle-wrapper {
    @media screen and (max-width: 1199px) {
      display: flex;
      justify-content: space-between;
    }
    @media screen and (max-width: 920px) {
      flex-direction: column;
      align-items: center;
    }
    .mobile {
      display: none;
      @media screen and (max-width: 1199px) {
        display: block;
      }
      @media screen and (max-width: 920px) {
        display: none;
      }
    }
  }

  &__subtitle {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 2.8rem;
    @media screen and (max-width: 1199px) {
      max-width: 177px;
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
    @media screen and (max-width: 920px) {
      max-width: 290px;
      margin-top: 8px;
      font-size: 1.8rem;
      line-height: 2.8rem;
    }
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    margin-bottom: 8px;
    @media screen and (max-width: 1199px) {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: auto;
    }
    @media screen and (max-width: 920px) {
      align-items: center;
      max-width: 290px;
      width: 100%;
      margin: 0 auto;
    }
  }

  .action {
    padding: 12px 20px;
    margin-bottom: 32px;
    @media screen and (max-width: 1199px) {
      order: 2;
      margin-top: 16px;
      margin-bottom: 0;
    }
    @media screen and (max-width: 920px) {
      order: 1;
      width: 100%;
      height: 51px;
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  &__link {
    position: relative;
    display: block;
    padding-left: 32px;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 3.6rem;
    color: $dark;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      background-image: url("../img/phone-Icon.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
    }
    @media screen and (max-width: 1199px) {
      order: 1;
    }
    @media screen and (max-width: 920px) {
      order: 2;
      margin-bottom: 8px;
    }
  }

  &__time {
    max-width: 225px;
    font-size: 1.6rem;
    line-height: 2.3rem;
    br {
      display: none;
    }
    @media screen and (max-width: 1199px) {
      display: none;
      font-size: 1.4rem;
      line-height: 2rem;

      br {
        display: block;
      }
    }
    @media screen and (max-width: 920px) {
      display: block;
    }
  }
}