@import "color.scss";

.product-card {
  padding-bottom: 42px;
  @media screen and (max-width: 1199px) {
    padding-bottom: 30px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__contact {
    width: 100%;
    max-width: 373px;
    padding: 33px 33px 40px;
    background-color: $callback-color;
    @media screen and (max-width: 1199px) {
      max-width: 276px;
      padding: 23px 23px 36px;
    }
    @media screen and (max-width: 767px) {
      order: 2;
      max-width: 540px;
      padding: 32px 50px;
    }
  }

  .contact-title {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 4rem;
    @media screen and (max-width: 1199px) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
  }

  .contact-list {
    padding-left: 20px;
    margin-top: 16px;
    margin-bottom: 24px;
    @media screen and (max-width: 1199px) {
      margin-bottom: 16px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 22px;
    }
  }

  .list-item {
    list-style: disc;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }

  .contact-decor {
    position: relative;
    max-width: 230px;
    padding: 7px 7px 7px 35px;
    border-radius: 25px;
    background-color: $white-color;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 140%;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translate(0, -50%);
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background-color: $green;
    }
  }

  .contact-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 200px;
    margin-top: 24px;
    @media screen and (max-width: 1199px) {
      margin-top: 16px;
    }
    @media screen and (max-width: 767px) {
      max-width: 330px;
    }
  }

  .link-item {
    display: block;
    width: 32px;
    height: 32px;
    &:hover {
      .icon-hover {
        fill: $dark;
        transition: .3s;
      }
      .border-hover {
        stroke: $dark;
        transition: .3s;
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 21px;
    @media screen and (max-width: 1199px) {
      margin-left: 16px;
    }
    @media screen and (max-width: 767px) {
      max-width: 540px;
      margin-left: 0;
      order: 1;
    }
    @media screen and (max-width: 425px) {
      flex-direction: column;
    }
  }

  .item-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .item-img {
    width: 49%;
    height: 362px;
    object-fit: contain;
    border: 1px solid $border-decor;
    background: $background;
    @media screen and (max-width: 767px) {
      height: 160px;
      width: 50%;
    }
    @media screen and (max-width: 425px) {
      height: 140px;
      width: 33%;
    }
  }

  .item-desc {
    display: flex;
    flex-direction: column;
    width: 49%;
    @media screen and (max-width: 767px) {
      width: 45%;
    }
    @media screen and (max-width: 425px) {
      width: 65%;
    }
  }

  .desc-title {
    margin-bottom: 24px;
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 4rem;
    @media screen and (max-width: 1199px) {
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 3.2rem;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 16px;
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
  }

  .desc-list {

  }

  .desc-item {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 16px;
    @media screen and (max-width: 767px) {
      margin-bottom: 8px;
    }
  }

  .item-title {
    font-size: 1.6rem;
    @media screen and (max-width: 1199px) {
      font-size: 1.4rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  .desc-text {
    width: 100%;
    max-width: 230px;
    margin-left: 8px;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    @media screen and (max-width: 1199px) {
      max-width: 130px;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
    @media screen and (max-width: 767px) {
      max-width: 115px;
      font-size: 1.1rem;
    }
    @media screen and (max-width: 359px) {
      max-width: 100px;
    }
  }

  .btn-desktop {
    width: 100%;
    max-width: 200px;
    height: 56px;
    margin-top: auto;
    @media screen and (max-width: 767px) {
      margin-top: 24px;
      margin-bottom: 32px;
    }
    @media screen and (max-width: 425px) {
      display: none;
    }
  }
  .btn-mobile {
    display: none;
    height: 56px;
    @media screen and (max-width: 425px) {
      display: flex;
      margin-top: 24px;
      margin-bottom: 32px;
    }
  }
}

.product-desc {
  padding-top: 42px;
  padding-bottom: 40px;
  @media screen and (max-width: 1199px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 25px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__tab {
    width: 66%;
    border: 1px solid $border-decor;
    @media screen and (max-width: 1199px) {
      border: none;
      width: 70%;
    }
    @media screen and (max-width: 920px) {
      width: 100%;
    }
  }

  .tab-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $border-decor;
  }

  .tab-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;
    padding: 14px 0;
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 3.2rem;
    color: $grey;
    transition: .3s;
    &.active {
      color: $dark;
      border-bottom: 1px solid $yellow;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
  }

  &__text {
    display: none;
    padding: 35px 32px;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 150%;
    transition: .3s;
    &.active {
      display: block;
    }
    @media screen and (max-width: 1199px) {
      padding: 28px 21px;
    }
  }
}

.product-form {
  padding-top: 42px;
  padding-bottom: 40px;
  @media screen and (max-width: 1199px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 25px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .text-wrapper {
    @media screen and (max-width: 1199px) {
      max-width: 347px;
    }
  }

  .btn-block {
    width: 100%;
    max-width: 373px;
    height: fit-content;
    padding: 32px;
    background-color: $callback-color;
    @media screen and (max-width: 1199px) {
      display: flex;
      justify-content: space-between;
      order: 2;
      max-width: 880px;
      padding: 32px 130px;
      margin-top: 16px;
    }
    @media screen and (max-width: 920px) {
      padding: 32px 80px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      padding: 32px 18px;
    }
  }

  .block-title {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 4rem;
    @media screen and (max-width: 767px) {
      text-align: center;
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 3.5rem;
    }
  }

  .block-text {
    margin-top: 16px;
    margin-bottom: 32px;
    font-size: 1.8rem;
    line-height: 150%;
    @media screen and (max-width: 1199px) {
      margin-bottom: 0;
    }
    @media screen and (max-width: 767px) {
      text-align: center;
      margin-top: 13px;
      margin-bottom: 16px;
    }
  }

  .block-btn {
    @media screen and (max-width: 1199px) {
      margin-top: auto;
      width: 100%;
      max-width: 330px;
    }
  }

  .form-body {
    padding: 32px;
    @media screen and (max-width: 1199px) {
      max-width: 880px;
      order: 1;
    }
    @media screen and (max-width: 767px) {
      padding: 16px;
    }
  }

  .form-title {
    margin-bottom: 22px;
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 4rem;
    @media screen and (max-width: 1199px) {
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 3.5rem;
    }
  }

  .form-upload {
    margin-left: 154px;
    @media screen and (max-width: 767px) {
      margin-left: 92px;
    }
  }

  .input-wrap {
    display: flex;
    @media screen and (max-width: 767px) {
      padding-left: 0;
    }
  }

  .input-label {
    position: static;
    max-width: 154px;
    @media screen and (max-width: 767px) {
      max-width: 77px;
      margin-right: 15px;
    }
  }

  .form-submit {
    margin-left: 154px;
    @media screen and (max-width: 767px) {
      margin-left: 92px;
    }
    @media screen and (max-width: 425px) {
      margin: 0 auto;
    }
  }
}

.product-page {

  .map {
    .subtitle {
      margin-left: 0;
    }
    .img-wrapper {
      position: relative;
      margin-top: -135px;
      margin-right: -20px;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: -290px;
        transform: translate(0, -50%);
        background-image: url("../img/backgrounf-decor.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 1043px;
        height: 1043px;
        z-index: -5;
        @media screen and (max-width: 1199px) {
          width: 790px;
          height: 790px;
          right: -145px;
        }
        @media screen and (max-width: 767px) {
          width: 345px;
          height: 345px;
        }
      }
      @media screen and (max-width: 1199px) {
        margin-top: -100px;
      }
      @media screen and (max-width: 767px) {
        margin-top: 0;
      }
    }

    .map-img {
      width: 1293px;
      height: 713px;
      @media screen and (max-width: 1199px) {
        width: 910px;
        height: 540px;
      }
      @media screen and (max-width: 767px) {

      }
    }
  }

  .delivery {
    padding-bottom: 140px;
    @media screen and (max-width: 1199px) {
      padding-bottom: 120px;
    }
    @media screen and (max-width: 767px) {
      padding-bottom: 40px;
    }

    &__wrapper {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1199px) {

      }
      @media screen and (max-width: 767px) {

      }
    }

    &__item-1 {
      width: 66%;
      height: 500px;
      @media screen and (max-width: 1199px) {
        height: 375px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        height: auto;
        margin-bottom: 24px;
      }
    }

    &__item-2 {
      width: 33%;
      height: 445px;
      @media screen and (max-width: 1199px) {
        height: 370px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        height: auto;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media screen and (max-width: 767px) {
        height: 196px;
      }
    }

    &__desc {
      margin-top: 8px;
      font-size: 3.2rem;
      font-weight: 400;
      line-height: 4rem;
      @media screen and (max-width: 1199px) {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
      @media screen and (max-width: 767px) {
        font-size: 1.8rem;
        line-height: 150%;
      }
    }
  }
}
